/*------------------------------------------------------------------
Project:    Fitfastnfab
Author:     Peter Pike.
URL:        http://fitfastnfab/letsgetfit
            https://twitter.com/YevSim
Version:    0.1
Created:        30/06/2015
Last change:    30/06/2015
-------------------------------------------------------------------*/
@media (min-width: 1200px){
.container {
    width: 1170px;
}
}


.btn-pink {
    background: #ec008b;
    border-color:#ec008b;
    color:#ffffff;
    border-radius: 30px;
    
}
/*-------
.wrapper {
    background-color: #F5F5F5;
 		padding-top: 50px;
}
------*/
#chatter #chatter_header {
    background: #263238;
    width: 100%;
    min-height: 80px;
    margin-top: 50px;
}

.float-left {
    float:left;
}

.link-padding {
    margin-top: 100px;
}

.fill { 
    min-height: 100%;
    height: 100%;
}       


    div .setHeight{
   height:700px;
}

.h3 {
    color:#ec008b;
}
.fff-color {
  color: #ec008b;

}
#sidemenu {
    padding-top:25px;

}
.btn-primary {
        border-radius: 25px;
}
.btn-success {
        border-radius: 25px;
}
.btn-warning {
        border-radius: 25px;
}
.btn-danger {
        border-radius: 25px;
}
.no-left-padding {
    padding-left: 0
}
 #add-meal .form-control-feedback,
 #add-meal .selectContainer .form-control-feedback {
    top: +10;
    right: -15px;
}
 #add-meal .inputGroupContainer .form-control-feedback {
    top: +10;
    right: -30px;
}
.hidden {
     display:none;
}

.nav-tabs > li > a {
    border-bottom: 0px solid #a94442;

}

.navbar-inverse .navbar-brand {
  color: #ec008b;
}
#wrap,
#hp-slider {
  color: #ec008b;
}

#tab-menu.collapse {
	background-color: #F5F5F5;
    border-style: hidden;
}

 .navbar {
    border-radius: 0;
    border-style: none;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.clear_navbar{
		margin-top: 70px;
}
