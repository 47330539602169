/*------------------------------------------------------------------
Project:    Highland
Author:     Yevgeny S.
URL:        http://simpleqode.com/
            https://twitter.com/YevSim
Version:    1.5.0
Created:        11/03/2014
Last change:    20/02/2015
-------------------------------------------------------------------*/

html, body {
  font-family: 'Lato', sans-serif;
  height: 100%;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
}
h1.hl, h2.hl, h3.hl, h4.hl, h5.hl, h6.hl {
  color: #515151;
  font-weight: 300;
  text-transform: uppercase;
}
h1.hl small, h2.hl small, h3.hl small, h4.hl small, h5.hl small, h6.hl small {
  text-transform: none;
}

/* ===== Sticky Footer Trick ===== */
.thumbnail{
  height:540px;
}
.wrapper {
  min-height: 100%;
  height: auto !important; /* for older versions of IE */
  height: 100%;
  margin-bottom: -347px; /* showing the footer */
  padding-top: 50px;
  padding-bottom: 347px; /* avoiding content go behind the footer */
}

/* ===== Navbar ===== */

.navbar-inverse {
  background-color: #303030;
  border: none;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  font-weight: bold;
  background-color: #7F757A   ;  
}
.navbar-inverse .navbar-brand {
  color: #BF21B3;
}
.navbar-inverse .navbar-nav > li > a {
  color: #EEEEEE;
}
.navbar-inverse {
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}
.navbar-inverse .navbar-nav > .dropdown > a .caret {
  border-top-color: #EEEEEE;
  border-bottom-color: #EEEEEE;
}
.navbar-inverse .navbar-nav > .open > a .caret,
.navbar-inverse .navbar-nav > .open > a:hover .caret,
.navbar-inverse .navbar-nav > .open > a:focus .caret {
  border-top-color: #515151;
  border-bottom-color: #515151;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #EEEEEE;
  color: #515151;
}
.dropdown-menu {
  background-color: #EEEEEE;
  border: 1px solid #D6D6D6;
  border-top-color: #EEEEEE;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dropdown-menu > li > a {
  color: #515151;
  padding: 10px 20px;
  font-size: 12px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #C922B0;
  background-color: #515151;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #515151;
}

/* ===== Showcase ===== */

#wrap,
#hp-slider {
  height: auto;
  /* === background: url(../img/1KmTimeTrial.jpg) no-repeat center center; == */
  background-color:#2E3333; 
  background-size: cover;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/bg4.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/bg4.jpg', sizingMethod='scale')";
  border-bottom: 1px solid #eee;
  color: #C922B0;

}
#wrap h1,
#hp-slider h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 42px;
  text-transform: uppercase;
  margin-left: 70px;
  text-shadow: 1px 1px 3px #666;
}
.showcase {
  position: relative;
  width: 438px;
  height: 300px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.iMac {
  position:  absolute;
  border-radius:5px;
  height: 300px;
  width: 450px;
  top: 0;
  right: 0;
  -webkit-animation-duration: 1s;
  -moz-animation-delay: 1s;
}
.iPad {
  position: absolute;
  height: 228px;
  width: 174px;
  top: 75px;
  left: 0;
  -webkit-animation-duration: 1.3s;
  -moz-animation-delay: 1.3s;
}
.iPhone {
  position: absolute;
  height: 152px;
  width: 56px;
  top: 145px;
  left: 130px;
  -webkit-animation-duration: 1.6s;
  -moz-animation-delay: 1.6s;
}
.macbook img {
  width: 450px;
  margin: 105.5px auto 0;
}
.list {
  margin: 50px 0 50px 70px;
}
.list ul {
  list-style-type: none;
  padding: 0;
}
.list li {
  font-weight: 400;
  font-size: 28px;
  color:#fff;
}
.list li > span {
  padding: 5px 10px;
  line-height: 1.8;
}
.list li i.fa {
  margin-right: 20px;
}
.first {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
}
.second {
  -webkit-animation-duration: 1.3s;
  -moz-animation-duration: 1.3s;
}
.third {
  -webkit-animation-duration: 1.6s;
  -moz-animation-duration: 1.6s;
}
.fourth {
  -webkit-animation-duration: 1.9s;
  -moz-animation-duration: 1.9s;
}
.delay {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
}

/* ===== Services ===== */

.services {
  margin-top: 10px;
}
.services__item {
  padding: 0 0 1px 0;
  background: white;
  overflow: hidden;
}
.services__item::last-item {
  padding: 0;
}
.services-item__body {
  padding: 20px;
  background: #eee;
  text-align: center;
  color: #888;
}
.services-item__caption {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
}
.services__item:hover .services-item__link,
.services__item:hover .services-item__link:hover,
.services__item:hover .services-item__link:focus {
  color: white;
}
.services__item:hover .services-item__body {
  background: #69D2E7;
  color: white;
}

@media(min-width: 768px) {
  .services__item {
    width: 20%;
    float: left;
    padding: 0 1px 0 0;
  }
}
@media(min-width: 992px) {
  .services__item {
    -webkit-transition: width .3s;
         -o-transition: width .3s;
            transition: width .3s;
  }
  .services-item__body {
    height: 100px;
  }
  .services-item__link {
    display: none;
  }
  .services:hover .services__item {
    width: 18%;
  }
  .services__item:hover {
    width: 28% !important;
  }
  .services__item:hover .services-item__body {
    text-align: left;
    cursor: pointer;
  }
  .services__item:hover .services-item__icon {
    float: left;
    margin: 0 20px 0 5px;
    line-height: 60px;
  }
  .services__item:hover .services-item__content {
    overflow: hidden;
  }
  .services__item:hover .services-item__link,
  .services__item:hover .services-item__link:hover,
  .services__item:hover .services-item__link:focus {
    display: block;
  }
}


/* ===== Profiles ===== */

/* Avatar */

.avatar {
  position: relative;
}
.avatar-bubble,
.avatar:hover .avatar-bubble {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.avatar-bubble {
  position: absolute;
  bottom: 11px;
  left: 11px;
  right: 11px;
  width: inherit;
  padding: 15px;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-weight: 300;
  opacity: 0;
}
.avatar:hover .avatar-bubble {
  opacity: 1;
}
.avatar-bubble a {
  display: block;
  color: #eee;
}
.avatar-bubble a:hover {
  text-decoration: none;
  color: #fff;
}

/* Profile menu */

.user-menu > ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.user-menu > ul > li {
  border-bottom: 1px solid #EEE;
  border-right: 1px solid #EEE;
}
.user-menu > ul > li:last-child {
  border-bottom: 0;
}
.user-menu > ul > li > a {
  display: block;
  padding: 10px;
}
.user-menu > ul > li > a:hover {
  text-decoration: none;
  background: #f5f5f5;
}
.user-menu > ul > li > a.active {
  background: #f5f5f5;
}
.user-menu > ul > li > a > i.sign {
  display: inline-block;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
}
.user-menu > ul > li > a > i.fa-chevron-right {
  line-height: 40px;
  color: #515151;
  display: none;
}
.user-menu > ul > li > a:hover > i.fa-chevron-right {
  display: inline-block;
}
.user-menu > ul > li > a.active:hover > i.fa-chevron-right {
  display: none;
}

/* User status */

.user-status {
  margin-bottom: 20px;
}
.user-status > p > a {
  color: #333;
}
.user-status .input-group{
  padding: 5px;
  background: #f5f5f5;
}

/* Modal window */

.modal-content {
  border-radius: 0;
}
.modal-header {
  background: #f5f5f5;
}
.modal-header > img, 
.message-answer > img {
  height: 50px;
  width: 50px;
  margin: 0 15px 15px 0;
  float: left;
  overflow: hidden;
}

/* User Stats and Gallery */

.block-inverse {
  border-radius: 5px;
  border: 1px solid #eee;
}
.block-inverse > .head-inverse {
  background: #F5F5F5;
  padding: 10px;
  border-bottom: 1px solid #EEE;
}
.block-inverse > .body-inverse {
  padding: 10px;
}
.user-gallery img {
  height: 60px;
  width: 60px;
  background: #FFF;
  border: 1px solid #dedede;
  border-radius: 60px;
  padding: 3px;
  margin: 5px;
}
.user-gallery img:hover {
    border-color: #999;
}

/* Inbox */

.pm-search {
  background: #F5F5F5;
  border-top: 1px solid #DDD;
  padding: 20px;
  margin-bottom: 15px;
}
.pm-list > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.pm-list > ul > li:first-child {
  border-top: 1px solid #EEE;
}
.pm-list > ul > li {
  padding: 15px;
  border-bottom: 1px solid #EEE;
}
.pm-list > ul > li:hover {
  background: #f5f5f5;
}
.pm-list > ul > li > p > img {
  height: 50px;
  width: 50px;
  float: left;
  margin-right: 15px;
  overflow: hidden;
}
.pm-list > ul > li > p {
  float: left;
  overflow: hidden;
  width: 50%;
  margin-bottom: 0;
}
.pm-list > ul > li > p > a.inbox-dialog {
  color: #999;
}
.pm-list > ul > li > p > a.inbox-dialog:hover {
  color: #999;
  text-decoration: none;
}
.message-answer {
  background: #F5F5F5;
  border-top: 1px solid #DDD;
  padding: 20px;
}
.message-answer > form {
  width: 80%;
  float: left;
} 

/* ===== Blog ===== */

.blog-summary {
  position: relative;
  margin-bottom: 40px;
}
.blog-summary > h4 {
  line-height: 22px;
}
.blog-text {
  line-height: 1.8;
}
.timeline-block .blog-img { /* Making images smalled for timeline page */
  width: 250px;
}
.blog-img {
  padding: 10px;
  border: 1px solid #ddd;
  margin: 10px;
  width: 350px;
}
.new-story:after {
  content: "NEW!";
  font-size: 0.6em;
  color: #FFF;
  background: #f1c40f;
  padding: 3px 4px;
  margin-left: 5px;
  vertical-align: super;
}
.recent-blogs ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.recent-blogs ul li {
  padding: 7px 0;
  border-bottom: 1px dotted #ddd;
}
.recent-blogs ul li:last-child {
  border-bottom: 0;
}
.blog-months ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 50%;
  border-right: 1px solid #FFF;
}
.blog-months ul li .badge {
  background: #FFF;
  color: #666;
  border: 1px solid #ddd;
}
.new-comment {
  padding: 20px 10px 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px dashed #ddd;
  margin: 20px 0;
}
.new-comment:hover {
  border-bottom-style: solid;
}
.new-comment img {
  max-height: 64px;
  width: auto;
  float: right;
}
.comment {
  margin: 10px 0;
  border-bottom: 1px dotted #ddd;
}

/* ===== Blog Timeline ===== */

.timeline-block {
  border-left: 3px solid #eee;
  padding-left: 20px;
  margin-left: 100px;
}
.timeline-block time.timestamp {
  position: absolute;
  top: 12px;
  left: -136px;
  padding: 5px 6px;
  font-style: italic;
  color: #999;
}
.timeline-block time.timestamp:before {
  display: block;
  font-family: FontAwesome;
  content: "\f073";
  font-style: normal;
  font-size: 32px;
  margin-left: 27px;
}
.timeline-block time.timestamp:after {
  display: inline-block;
  content: "";
  height: 20px;
  width: 20px;
  border: 3px solid #DDD;
  border-radius: 26px;
  margin-left: 16px;
  background: #FFF;
}

/* ===== About Us block ===== */

img.img-about {
  width: 40%;
  padding: 10px;
  border: 1px solid #eee;
  float: left;
  margin: 0 20px 20px 0;
}

/* ===== Media objects ===== */

.media-object {
  height: 64px;
  width: 64px;
}
.media-heading a{
  color: #333;
}
.media-heading a:hover{
  color: #333;
  text-decoration: none;
}
.read-more {
  float:right;
  margin-top: 10px;
}

/* ===== Recent Works ===== */

.thumbnail {
  position: relative;
}
.thumbnail .visit {
  position: absolute;
  opacity: 0;
  width: 100%;
  text-align: center;
  top: 80px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.thumbnail:hover .visit {
  opacity: 1;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.thumbnail .visit a {
  color: #FFF;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
}
.thumbnail .visit a:hover {
  color: #FFF;
  text-decoration: none;
}

/* ===== Steps ===== */

.step {
  text-align: center;
  width: 50%;
  margin: 0 auto;
}
.step i {
  color: #dedede;
  transition: color 0.3s;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
}
.step h4 {
  text-transform: uppercase;
}
.step .arrow {
  position: absolute;
  top: 0;
  right: -10%;
  color: #dedede;
  font-size: 100px;
  transition: color 0.3s;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
}
.step:hover i {
  color: #FFA973;
  transition: color 0.3s;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
}
.step:hover .arrow {
  color: #99E0FF;
  transition: color 0.3s;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
}

/* ===== About Us ===== */

.about-icon {
  font-size: 52px;
  float: left;
  margin: 15px 15px 0 0;
  color: #81D685;
}
.about-p {
  overflow: hidden;
  line-height: 22px;
}
.about-btn {
  margin-top: 50px;
  text-align: center;
}

/* ===== Team ===== */
.member-left {
  text-align: left;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px dotted #eee;
}
.member-right {
  text-align: right;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px dotted #eee;
}
.team img,
.team img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
.team .member-left img{
  float: left;
  margin-right: 50px;
}
.team .member-right img{
  float: right;
  margin-left: 50px;
}
.team .position {
  color: #ccc;
}
.team p {
  overflow: hidden;
}

/* ===== 404 error page ===== */

.not-found {
  background: #eee;
  background-image: url(../img/falling-leafs.png), -webkit-linear-gradient(top, #eee 0%, #fff 100%);
  background-image: url(../img/falling-leafs.png), -o-linear-gradient(top, #eee 0%, #fff 100%);
  background-image: url(../img/falling-leafs.png), linear-gradient(to bottom, #eee 0%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eee', endColorstr='#fff', GradientType=0);
}
.not-found .digits {
  color: #999;
  font-size: 250px;
  font-weight: bold;
  text-align: center;
}
.not-found .digits i {
  font-size: 200px;
  margin: 0 10px;
}
.not-found h1 {
  text-align: center;
  color: #666;
}
.not-found h2 {
  text-align: center;
  color: #999;
}
.not-found form {
  text-align: center;
  margin: 30px 0;
}


/* ===== Contact Us ===== */

/* Alert Message */

#form_message {
  display: none;
}
#form_message.alert-success {
  display: block;
}

/* Help Block (error messages) */

#form_sendemail .help-block {
  color: #ff5722 !important;
  display: none;
}
#form_sendemail .has-error .help-block,
#form_sendemail .has-error.help-block {
  display: block;
}
/* reCaptcha */
#recaptcha_image {
  margin-bottom: 15px;
}
#recaptcha_image > img {
  border: 1px solid #FFF;
}
#recaptcha_image,
#recaptcha_image > img {
  width: 100% !important;
  max-width: 300px;
  height: auto !important;
}
#recaptcha_audio_play_again {
  cursor: pointer;
}

/* reCaptcha iframe bug fix (causing an emptly line just before
  the closing </body> tag */
  
iframe[src="about:blank"] {
  display: none !important;
}


/* ===== Pricing ===== */

.pricing .item {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 3px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.pricing .item:hover {
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 0 20px rgba(0,0,0,0.2);
  margin-top: -10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.pricing .head {
  background: #C922B0;
  padding: 30px 10px 10px;
  position: relative;
  border-radius: 3px;
  margin-bottom: 50px;
}
.pricing .head h4 {
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
}
.pricing .head .arrow {
  position: absolute;
  height: 30px;
  width: 100%;
  border-top: 30px solid #C922B0;
  border-right: 120px solid white;
  border-left: 120px solid white;
  bottom: -30px;
  left: 0;
}
.pricing .sceleton {
  text-align: center;
}
.pricing .sceleton h5 {
  font-size: 30px;
}
.pricing .sceleton span {
  font-size: 24px;
}
.pricing .sceleton ul {
  margin: 0 0 10px;
  padding: 0;
}
.pricing .sceleton li {
  list-style-type: none;
  padding: 10px 0;
  border-top: 1px dotted #ccc;
}
.pricing .sceleton li:hover {
  border-top: 1px solid #ccc;
  background: #eee;
  cursor: pointer;
}

/* ===== FAQs ===== */

.faq-cats ul {
  list-style-type: none;
  padding: 0; 
  margin: 0;
}
.faq-cats ul li:first-child {
  margin: 0 0 15px 0;
}
.faq-cats ul li {
  margin: 15px 0 15px 0;
}
.faq-cats ul li i{
  vertical-align: middle;
  margin-right: 15px;
  color: #bbb;
}
.faq-cats ul li:hover i{
  color: #00B4FF;
}

/* ===== Gallery ===== */

.gallery__nav {
  margin-bottom: 20px;
}
.gallery__item {
  position: relative;
  margin-bottom: 20px;
}

/**
* Gallery bubble
*/

.gallery-bubble {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px;
  background: rgba(0, 0, 0, .5);
  color: white;
  opacity: 0;
  -webkit-transition: opacity 0.25s;
       -o-transition: opacity 0.25s;
          transition: opacity 0.25s;
}
.gallery__item:hover .gallery-bubble {
  opacity: 1;
}

/**
 * Gallery text blocks
 */

.gallery__item > a {
  color: #333;
}
.gallery__item > a:hover,
.gallery__item > a:focus {
  text-decoration: none; 
}
.gallery-text {
  padding: 5px 10px;
  margin-top: 1px;
  -webkit-transition: all .25s;
       -o-transition: all .25s;
          transition: all .25s;
}
.gallery-title {
  margin-bottom: 5px;
  font-size: 18px;
}
.gallery__item:hover .gallery-text {
  background: #00B4EF;
  color: white;
}


/* ===== Gallery Item ===== */

.gallery-show .carousel-inner > .item > img,
.gallery-show .carousel-inner > .item > a > img {
  margin: 0 auto;
}

.gallery-item-icons {
  font-size: 24px;
  color: #444;
}
.gallery-list-small li {
  height: 50px;
  overflow: hidden;
  margin-bottom: 10px;
}
.gallery-list-small > li:first-child {
  padding-left: 5px !important;
}

/* Showcase controls */

a.carousel-arrow {
  position: absolute;
  display: block;
  top: 50%;
  margin-top: -50px;
  height: 100px;
  width: 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  color: #FFF;
  font-size: 32px;
  font-weight: 300;
  line-height: 100px;
  cursor: pointer;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  opacity: 0;
}
.carousel:hover a.carousel-arrow {
  opacity: 1;
}
a.carousel-arrow:hover,
a.carousel-arrow:active {
  text-decoration: none;
  background: #00B4FF;
}
a.carousel-arrow-prev {
  left: 0;
  right: auto;
  padding-right: 5px;
}
a.carousel-arrow-next {
  right: 0;
  left: auto;
  padding-left: 5px;
}

/* ===== Footer ===== */

footer {
  padding: 20px 0;
  background: #3E7A8C;
  color: white;
  font-weight: 300;
}
footer a {
  color: #81D685;
}
footer a:hover {
  color: #81D685;
  text-decoration: none;
}
footer .headline {
  border-bottom: 1px dotted white;
  margin-bottom: 20px;
}
footer .headline h3{
  text-transform: uppercase;
  font-weight: 100;
}
.legal {
  padding: 20px 0 10px 0;
  background: #386F80;
  color: white;
  font-weight: 300;
}
.legal a {
  color: #81D685;
  text-decoration: none;
}
.social ul {
  background: #3E7A8C;
  color: white;
  font-size: 24px;
  margin: 0;
  padding: 0;
}
.social li {
  float: left;
  display: block;
  list-style-type: none;
  width: 28px;
  height: 28px;
  background: #eee;
  margin: 0 5px 5px 0;
  text-align: center;
  line-height: 28px;
}
.social li:hover {
  background: none;
}
.social li, .social li:hover {
  transition: background 0.1s;
  -webkit-transition: background 0.1s;
  -o-transition: background 0.1s;
}
.social li a {
  color: #3E7A8C;

}
.social li:hover a {
  color: #fff;
}
.social li a, .social li:hover a {
  transition: color 0.1s;
  -webkit-transition: color 0.1s;
  -o-transition: color 0.1s;
}
footer .btn-default {
  color: #fff;
  background: none;
}
footer .btn-default:hover {
  color: #3E7A8C;
  background: #fff;
  font-weight: bold;
  border: 1px solid #fff;
}
footer .form-control {
  border: white;
}
footer .form-control:focus {
  border: white;
}
footer .sbtn {
  margin-bottom: 5px;
}

/* Login Header*/

li.account {
  padding: 10px 20px;
  overflow: hidden;
  min-width: 350px;
}
li.account .avatar {
  width: 100px;
  height: 100px;
  float: left;
  margin: 0 20px 0 0;
  background: url(../img/face1.jpg);
  background-size: cover;
  background-position: center;
}
li.account p{
  overflow: hidden;
  font-size: 14px;
  text-transform: none;
  word-wrap: break-word;
}

/* ===== Login box ===== */

.form-box {
  padding: 10px 15px 20px;
  border: 1px solid #DDD;
  background: #F5F5F5;
}
.form-box h4 {
  text-transform: uppercase;
  color: #666;
}
.form-box hr {
  border-bottom: 1px solid #dedede;
}
.login-social ul {
  font-size: 20px;
  margin: 0;
  padding: 0;
}
.login-social li {
  float: left;
  display: block;
  list-style-type: none;
  width: 40px;
  height: 40px;
  margin: 20px 5px 20px 0;
  text-align: center;
  line-height: 40px;
}
.login-social li#twitter {
  background: #1dcaff;
}
.login-social li#facebook {
  background: #3B5998;
}
.login-social li#google {
  background: #dd4b39;
}
.login-social li#twitter:hover,
.login-social li#facebook:hover,
.login-social li#google:hover {
  background: none;
}
.login-social li#twitter:hover a {
  color: #1dcaff;
}
.login-social li#facebook:hover a {
  color: #3B5998;
}
.login-social li#google:hover a {
  color: #dd4b39;
}
.login-social li a {
  color: #fff;
  padding: 10px;
}
.login-social li, .login-social li:hover {
  transition: background 0.3s;
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
}
.login-social li a, .login-social li:hover a:hover{
  transition: color 0.3s;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
}
/* ===== Registration form popover ===== */

.signup .popover {
  min-width: 200px;
}

/* ===== Site search ===== */

.navbar-nav li#search {
  background: #00B4FF;
  background: linear-gradient(top, #00B4FF, #009DE0);
  background: -webkit-linear-gradient(top, #00B4FF, #009DE0);
  background: -moz-linear-gradient(top, #00B4FF, #009DE0);
  position: relative;
}
.navbar-nav li#search .search-box {
  position: absolute;
  right: 0;
  bottom: -70px;
  width: 250px;
  padding: 10px;
  background: #515151;
  background: rgba(0, 0, 0, 0.65);
  display: none;
}
.navbar-nav li#search i.margin-2 {
  margin-left: 2px;
}

/* ===== Pagination ===== */

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #428bca;
  border-color: #428bca;
}

/* Helper Classes */

.top-zero {
  margin-top: 0;
}
.bottom-15 {
  margin-bottom: 15px;
}
.ui-icon {
  color: #666;
}
.tab-1 {
  margin-right: 15px;
}
.tab-2 {
  margin-right: 30px;
}
.tab-3 {
  margin-right: 45px;
}
.tab-3 {
  margin-right: 60px;
}
.margin-bottom-xs {
  margin-bottom: 0;
}
@media (max-width: 767px) { /* Adjusting for mobile */
  .margin-bottom-xs {
    margin-bottom: 15px;
  }
}
.text-muted a {
  color: #999;
  text-decoration: none;
}
.text-muted a:hover {
  text-decoration: underline;
}

/* Text colors */

.text-green {
  color: #72a53b;
}
.text-blue {
  color: #00B4FF;
}
.text-red {
  color: #E74C3C;
}
.text-turquoise {
  color: #1ABC9C;
}
.text-amethyst {
  color: #9B59B6;
}

/* Background colors */

.bg-green {
  background-color: #72a53b;
}
.bg-blue {
  background-color: #00B4FF;
}
.bg-red {
  background-color: #E74C3C;
}
.bg-turquoise {
  background-color: #1ABC9C;
}
.bg-amethyst {
  background-color: #9B59B6;
}

/* ===== Scroll to Top ===== */

.scrolltotop {
  height: 48px;
  width: 48px;
  border-color: #999;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #999;
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
  line-height: 48px;
  border-radius: 3px;
  text-align: center;
}

/* ===== Responsive CSS ===== */

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: #303030;
}

@media (max-width: 1199px) { /* Adjusting for large devices */
  
  /* Pricing page arrows */
  
  .pricing .head .arrow {
    border-right-width: 95px;
    border-left-width: 95px;
  }

}
@media (max-width: 991px) { /* Adjusting for small devices */
  
  /* Pricing page arrows */
  
  .pricing .head .arrow {
    border-right-width: 68px;
    border-left-width: 68px;
    border-top-width: 20px;
  }
  
}
@media (max-width: 767px) { /* Adjusting for mobile */

  /* Navigation */
  
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
  color: #fff;
  background-color: transparent;
  }

  /* Index page background */
  
  #wrap h1, #hp-slider h1 {
    font-size: 32px;
    margin: 70px 0 0 0;
    text-align: center;
    font-weight: 300;
  }
  .list {
    margin: 50px 0;
  }
  .list li {
    margin: 5px 0;
    font-size: 20px;
    font-weight: 200;
    line-height: 2;
  }

  /* Steps */

  .step {
    text-align: left;
    width: auto;
    margin: 0 0 15px 0;
  }
  .step i {
    float: left;
    margin-right: 15px;
  }
  .step .arrow {
    display: none;
  }
  .step a.sbtn {
    margin-left: 65px;
  }
  
  /* Registration form popover */
  
  .signup .popover {
    display: none;
  }
  
  /* 404 */
  
  .not-found .digits {
    font-size: 140px;
  }
  .not-found .digits i {
    font-size: 100px;
  }
  .not-found h1 {
    font-size: 30px;
    line-height: 42px;
  }
  .not-found h2 {
    font-size: 18px;
  }
  
  /* Pricing page */
  
  .pricing .head {
    padding: 20px;
  }
  .pricing .head .arrow {
    border: 0;
  }
  .pricing .item {
    margin: 20px 0;
  }
  .pricing .item:hover {
    margin: 20px 0;
  }
  
  /* Showcase Carousel */

  a.carousel-arrow  {
    top: 55px;
    bottom: auto;
    height: 35px;
    width: 35px;
    border-radius: 0;
    color: #FFF;
    font-size: 18px;
    line-height: 35px;
  }
  a.carousel-arrow-prev {
    left: 50%;
    margin-left: -37px;
  }
  a.carousel-arrow-next {
    left: 50%;
  }
  
  /* Navbar dropdown */
  
  .dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #999;
  }
  
  /* Timeline */
  .timeline-block {
    border-left: 0;
    padding-left: 0;
    margin-left: 0;
  }

}
